@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Cera-Pro";
  src: url("./assets/CeraPRO-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  @apply bg-black text-white;
  font-family: Cera-Pro, "Poppins", sans-serif;
}

@media (-webkit-device-pixel-ratio: 1.25) {
  :root {}

  .react-datepicker-popper>div {
    margin-left: 200px !important;
  }

  .btn-sec {
    @apply text-sm rounded-md cursor-pointer py-2 px-6 duration-300 bg-slate;
  }

  .btn-danger {
    @apply text-sm cursor-pointer py-1 px-5 duration-300 bg-[#460404] hover:bg-red rounded-md;
  }

  .btn {
    @apply text-xl rounded-md cursor-pointer py-1 px-5 duration-300 bg-gradient-to-r from-green to-blue disabled:cursor-default disabled:text-slate disabled:shadow-none disabled:bg-black;
  }
}

.btn-cursordefault {
  @apply text-xl rounded-md py-1 px-5 duration-300 bg-gradient-to-r from-green to-blue disabled:cursor-default disabled:text-slate disabled:shadow-none disabled:bg-black;
}

*::-webkit-scrollbar {
  width: 15px;
  background: transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  @apply bg-scrollBar;
  border-radius: 20px;
  background-clip: padding-box;
  border: 5px solid rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb:hover {
  background-clip: padding-box;
  border: 5px solid rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* yscroll */

.y-scroll::-webkit-scrollbar {
  width: 15px;
  background: transparent;
}

.y-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.y-scroll::-webkit-scrollbar-thumb {
  @apply bg-scrollBar;
  border-radius: 20px;
  background-clip: padding-box;
  border: 5px solid rgba(0, 0, 0, 0);
}

.y-scroll::-webkit-scrollbar-thumb:hover {
  background-clip: padding-box;
  border: 5px solid rgba(0, 0, 0, 0);
}

.y-scroll::-webkit-scrollbar-corner {
  background: transparent;
}

.input {
  @apply rounded-md bg-black w-full py-1 border-2 border-lightGray px-2 outline-none focus:border-green focus:outline-none focus:ring-0 disabled:text-slate disabled:shadow-none;
}

.gradient {
  @apply bg-gradient-to-r from-green to-blue;
}

.btn-sec {
  @apply rounded-md cursor-pointer py-1 px-5 duration-300 bg-slate;
}

.btn-danger {
  @apply cursor-pointer py-1 px-5 duration-300 bg-[#460404] rounded-md;
}

.btn {
  @apply rounded-md cursor-pointer py-1 px-5 duration-300 bg-gradient-to-r from-green to-blue disabled:text-slate disabled:shadow-none disabled:bg-black;
}

.login-input {
  @apply rounded-md bg-transparent w-full py-1 border-2 border-slate bg-white text-dark px-2 outline-none focus:border-slate focus:outline-none focus:ring-0 disabled:text-slate disabled:shadow-none;
}

.login-btn {
  @apply rounded-md cursor-pointer py-1 px-5 duration-300 bg-slate text-white disabled:text-slate disabled:shadow-none disabled:bg-slate;
}

/* progress bar */

.Progressbar {
  position: relative;
  height: 32px;
  border-radius: 1000px;
  background-color: #333333;
  clip-path: inset(0 0 0 0 round 1000px);
}

.Progressbar__value {
  height: 25px;
  transition: width 0.4s ease-in-out;
  border-radius: 1000px 0 0 1000px;
  background-color: #47c2fd;
  will-change: width;
}

.Progressbar>progress {
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
  pointer-events: none;
}

/* AI */

@keyframes pulse-ai {
  0% {
    box-shadow: 0 0 0 5px #11999e;
  }

  100% {
    box-shadow: 0 0 0 20px transparent;
  }
}

@keyframes fade-bar {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.form-group {
  @apply flex w-full;
}

.form-group input {
  @apply w-[1/2];
}

/* Shadcn */

[cmdk-input-wrapper] {
  @apply border-slate !important;
}

[cmdk-item] {
  @apply bg-transparent hover:bg-transparent hover:text-green !important;
}

.logo-medium {
  width: 160px;
}

.logo-large {
  width: 280px;
}

/* laoding screen */
.loader-container {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: #06151ec7;
  /* backdrop-filter: blur(5px); */
  backdrop-filter: blur(100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-status {
  position: absolute;
  top: 50%;
  padding-top: 120px;
  color: #fff;
  width: 430px;
  margin-inline: auto;
  text-align: center;
}

.table-container-height {
  height: calc(92vh - 138px);
}

.alarm-container-height {
  height: calc(100vh - 143px);
}

.alarm-container-height-scaled {
  height: calc(165vh - 214px);
}

.table-container-height-scaled {
  height: calc(153vh - 210px);
}

.tickets-container-height-scaled {
  height: calc(153vh - 129px);
}

.tickets-container-height {
  height: calc(91vh - 57px);
}

.active-tab {
  position: relative;
}

.active-tab::before {
  content: '';
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  background-color: #111111;
}

@media (-webkit-device-pixel-ratio: 1) {
  .active-tab::before {
    height: 9px;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .active-tab::before {
    height: 14px;
  }

  .btn {
    @apply text-[14px] py-0.5 px-4;
  }
}

.panel .loader-container {
  background: none;
}

.loader-container.hide {
  display: none;
}

.loader-container .logo-medium {
  margin-top: -159px;
}

.stats-loader {
  width: 94%;
  height: 77%;
  position: absolute;
}

.stats-loader .logo-medium {
  display: none;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background-color: rgba(34, 47, 62, 0.2);
  box-shadow: 0 0 24px 4px rgba(0, 255, 215, 0.2);
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
}

.loader:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 50%;
  background-image: radial-gradient(circle,
      transparent 68%,
      "#14b8a6" 69%,
      #12e4de 70%);
  box-shadow: inset 0 0 0 0 rgba(42, 61, 56, 0);
  -webkit-animation: Glow 5s infinite ease-in-out;
  animation: Glow 5s infinite ease-in-out;
}

.loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  z-index: 999;
  border: solid 2px #13b9cf;
  border-radius: 50%;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  -webkit-animation: Spin 1.5s infinite linear;
  animation: Spin 1.5s infinite linear;
}

.loader-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #12e4de;
  letter-spacing: 12px;
  text-shadow: 0 0 24px rgba(42, 61, 56, 0.8);
  -webkit-animation: Pulse 5s infinite linear;
  animation: Pulse 5s infinite linear;
}

.loader-text:before {
  content: attr(data-flicker-0);
  position: absolute;
  left: 0;
  color: "#14b8a6";
  opacity: 0;
  text-shadow: 0 0 16px rgba(42, 61, 56, 0);
  -webkit-animation: Flicker 3s infinite linear;
  animation: Flicker 3s infinite linear;
}

.loader-text:after {
  content: attr(data-flicker-1);
  position: absolute;
  right: 0;
  color: #12e4de;
  opacity: 0;
  text-shadow: 0 0 16px rgba(42, 61, 56, 0);
  -webkit-animation: Flicker 3s infinite linear;
  animation: Flicker 3s infinite linear;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes Glow {
  50% {
    box-shadow: inset 0 0 24px 4px rgba(42, 61, 56, 0.8);
  }
}

@keyframes Glow {
  50% {
    box-shadow: inset 0 0 24px 4px rgba(42, 61, 56, 0.8);
  }
}

@-webkit-keyframes Spin {
  50% {
    opacity: 0.2;
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes Spin {
  50% {
    opacity: 0.2;
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes Pulse {
  50% {
    color: rgba(42, 61, 56, 0.5);
    text-shadow: 0 0 24px rgba(42, 61, 56, 0.4);
  }
}

@keyframes Pulse {
  50% {
    color: rgba(42, 61, 56, 0.5);
    text-shadow: 0 0 24px rgba(42, 61, 56, 0.4);
  }
}

@-webkit-keyframes Flicker {
  0% {
    opacity: 0.1;
    color: #12e4de;
    text-shadow: 0 0 16px #12e4de;
  }

  2% {
    opacity: 1;
    color: #fff;
    text-shadow: 0 0 24px #12e4de;
  }

  6% {
    opacity: 0.1;
    color: #12e4de;
    text-shadow: 0 0 8px #12e4de;
  }

  12% {
    opacity: 0.8;
    color: #fff;
    text-shadow: 0 0 8px #12e4de;
  }

  14% {
    opacity: 0.2;
    color: #12e4de;
    text-shadow: 0 0 8px #12e4de;
  }

  30% {
    opacity: 1;
    color: #fff;
    text-shadow: 0 0 16px #12e4de;
  }

  36% {
    opacity: 0.2;
    color: #12e4de;
    text-shadow: 0 0 24px #12e4de;
  }

  60% {
    opacity: 0.6;
    text-shadow: 0 0 16px #12e4de;
  }

  64% {
    opacity: 0.8;
    text-shadow: 0 0 18px #12e4de;
  }

  68% {
    opacity: 1;
    text-shadow: 0 0 24px #12e4de;
  }

  82% {
    opacity: 0.2;
    text-shadow: 0 0 16px #12e4de;
  }

  84% {
    opacity: 0.1;
    text-shadow: 0 0 8px #12e4de;
  }

  86% {
    opacity: 0.6;
    text-shadow: 0 0 12px #12e4de;
  }
}

@keyframes Flicker {
  0% {
    opacity: 0.1;
    color: #12e4de;
    text-shadow: 0 0 16px #12e4de;
  }

  2% {
    opacity: 1;
    color: #fff;
    text-shadow: 0 0 24px #12e4de;
  }

  6% {
    opacity: 0.1;
    color: #12e4de;
    text-shadow: 0 0 8px #12e4de;
  }

  12% {
    opacity: 0.8;
    color: #fff;
    text-shadow: 0 0 8px #12e4de;
  }

  14% {
    opacity: 0.2;
    color: #12e4de;
    text-shadow: 0 0 8px #12e4de;
  }

  30% {
    opacity: 1;
    color: #fff;
    text-shadow: 0 0 16px #12e4de;
  }

  36% {
    opacity: 0.2;
    color: #12e4de;
    text-shadow: 0 0 24px #12e4de;
  }

  60% {
    opacity: 0.6;
    text-shadow: 0 0 16px #12e4de;
  }

  64% {
    opacity: 0.8;
    text-shadow: 0 0 18px #12e4de;
  }

  68% {
    opacity: 1;
    text-shadow: 0 0 24px #12e4de;
  }

  82% {
    opacity: 0.2;
    text-shadow: 0 0 16px #12e4de;
  }

  84% {
    opacity: 0.1;
    text-shadow: 0 0 8px #12e4de;
  }

  86% {
    opacity: 0.6;
    text-shadow: 0 0 12px #12e4de;
  }
}

@keyframes pulse-ai {
  0% {
    box-shadow: 0 0 0 5px #11999e;
  }

  100% {
    box-shadow: 0 0 0 20px transparent;
  }
}

@keyframes fade-bar {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

/* react tel input */

.react-tel-input input {
  width: 240px !important;
  background: #232323 !important;
  color: #f1f5f9 !important;
}

.react-tel-input .flag-dropdown {
  background: #232323 !important;
}

.react-tel-input .selected-flag {
  background: #232323 !important;
}

.react-tel-input .country-list {
  background: #232323 !important;
}

.react-tel-input .country-list li:hover {
  background: #111111 !important;
}

.highlight {
  background: #111111 !important;
}

.react-tel-input .flag-dropdown:hover {
  background: #111111 !important;
}

.css-13cymwt-control {
  background: #232323 !important;
  color: #f1f5f9 !important;
}

.css-t3ipsp-control {
  background: #232323 !important;
  color: #f1f5f9 !important;
}

.css-1nmdiq5-menu {
  background: #232323 !important;
  color: #f1f5f9 !important;
}

.css-1nmdiq5-menu li:hover {
  background: #111111 !important;
}

.css-1dimb5e-singleValue {
  color: #f1f5f9 !important;
}

.css-1dimb5e-singleValue {
  color: #f1f5f9 !important;
}

/* Table */

button,
table,
th,
td,
tr {
  white-space: nowrap;
}

td,
th {
  @apply py-3 px-3;
}

/* calendar */

.date-time .calendar {
  width: 380px;
  font-family: Roboto-Mono, "Poppins", sans-serif !important;
  margin-right: -40px;
  background-color: #36435d63;
}

.date-time .tg-list-item {
  width: 350px;
  position: relative;
}

.date-time input.processhub-form-control {
  border: none;
  outline: none;
  width: 295px;
}

.card-content .date-time-play-pause {
  margin-left: 40px;
  margin-right: -10px;
}

.date-time .calendar .sidebar-card {
  position: absolute;
  top: 15px;
  color: "#4d4d4d";
}

.play-pause-btn:hover {
  color: #11999e;
}

.play-pause-btn-active {
  color: #11999e;
}

.date-time .calendar .react-datepicker__header,
.date-time .calendar .react-datepicker__current-month,
.date-time .calendar .react-datepicker-year-header,
.date-time .calendar .react-datepicker-time__header,
.date-time .react-datepicker-time__caption,
.date-time .react-datepicker-time__input {
  background: none;
  border-color: "#14b8a6";
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-weight: 400;
}

.react-datepicker-time__input {
  border: none;
  outline: none;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.date-time .calendar .react-datepicker__time,
.date-time .calendar .react-datepicker__time li {
  font-family: Roboto-Mono, "Poppins", sans-serif !important;
  background: none;
  font-size: 12px;
  color: "#4d4d4d";
}

.date-time .calendar .react-datepicker__time-container {
  border-color: "#14b8a6";
}

.date-time .calendar .react-datepicker__time {
  margin-bottom: 5px;
}

.date-time .calendar li.react-datepicker__time-list-item:hover,
.date-time .calendar .react-datepicker__day:hover {
  background-color: #12e4de !important;
  color: #000;
}

.date-time .calendar .react-datepicker__day-name,
.date-time .calendar .react-datepicker__day,
.date-time .calendar .react-datepicker__time-name {
  color: "#4d4d4d";
  font-size: 12px;
  font-weight: 400;
}

.date-time .calendar .react-datepicker__day--selected,
.date-time .calendar ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.date-time .calendar .react-datepicker__day--keyboard-selected {
  background-color: "#14b8a6" !important;
  font-weight: 400 !important;
}

.date-time .calendar .react-datepicker__day--disabled,
.date-time .calendar .react-datepicker__month-text--disabled,
.date-time .calendar .react-datepicker__quarter-text--disabled,
.date-time .calendar .react-datepicker__year-text--disabled {
  color: rgba(255, 255, 255, 0.3) !important;
}

.date-time .calendar .react-datepicker__day--disabled:hover,
.date-time .calendar .react-datepicker__month-text--disabled:hover,
.date-time .calendar .react-datepicker__quarter-text--disabled:hover,
.date-time .calendar .react-datepicker__year-text--disabled:hover {
  background-color: transparent !important;
}

.date-time .calendar .react-datepicker__navigation-icon::before {
  border-color: "#14b8a6";
  top: 11px;
}

.date-time-container .date-time-play-pause .play-pause-disable,
.tag-chart-container .icon-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.date-time-container .date-time-play-pause div:not(.play-pause-disable),
.date-time-container .date-time-play-pause div:not(.play-pause-disable) {
  cursor: pointer;
}

.sidebar-card {
  color: white;
  margin-top: 10px;
  background-color: #111111;
  border: solid 1.5px #12e4de;
  /* box-shadow: 0 0 1px  #12e4de, 0 0 6px  #12e4de; */
  /* backdrop-filter: blur(5px); */
  border-radius: 16px;
  padding: 5px;
  margin-bottom: 15px;
}

.sidebar-card .card-title {
  text-align: center;
  color: white;
  font-size: 15px;
  margin-bottom: 15px;
}

.sidebar-card .card-content {
  text-align: center;
}

/* ******************************************************************* */

.date-time .calendar li.react-datepicker__time-list-item:hover,
.date-time .calendar .react-datepicker__day:hover {
  background-color: "#14b8a6" !important;
  color: #000;
}

.date-time .calendar .react-datepicker__day-name,
.date-time .calendar .react-datepicker__day,
.date-time .calendar .react-datepicker__time-name {
  color: var(--text-grey);
  font-size: 12px;
  font-weight: 400;
}

.date-time .calendar .react-datepicker__day--selected,
.date-time .calendar ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.date-time .calendar .react-datepicker__day--keyboard-selected {
  background-color: var(--primary) !important;
  font-weight: 400 !important;
}

.date-time .calendar .react-datepicker__day--disabled,
.date-time .calendar .react-datepicker__month-text--disabled,
.date-time .calendar .react-datepicker__quarter-text--disabled,
.date-time .calendar .react-datepicker__year-text--disabled {
  color: rgba(255, 255, 255, 0.3) !important;
}

.date-time .calendar .react-datepicker__day--disabled:hover,
.date-time .calendar .react-datepicker__month-text--disabled:hover,
.date-time .calendar .react-datepicker__quarter-text--disabled:hover,
.date-time .calendar .react-datepicker__year-text--disabled:hover {
  background-color: transparent !important;
}

.date-time .calendar .react-datepicker__navigation-icon::before {
  border-color: var(--primary);
  top: 11px;
}

.date-time-container .date-time-play-pause .play-pause-disable,
.tag-chart-container .icon-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.date-time-container .date-time-play-pause div:not(.play-pause-disable),
.date-time-container .date-time-play-pause div:not(.play-pause-disable) {
  cursor: pointer;
}

.sample-expression span {
  color: #14b8a6;
  font-size: 19px;
}

.sample-expression p {
  margin-bottom: 5px;
  font-size: 19px;
}

.sample-expression label,
.sample-expression span {
  display: inline-block;
  margin-bottom: 5px;
}

.sample-expression label {
  color: #a1a1aa;
  font-size: 13px;
}

.sample-expression label {
  font-size: 19px;
}

.file-upload {
  font-size: 16px;
}

.file-upload label {
  color: #a1a1aa;
  font-size: 16px;
}

@media (-webkit-device-pixel-ratio: 1.25) {
  :root {}

  .sample-expression span {
    color: #14b8a6;
    font-size: 16px;
  }

  .file-upload label {
    font-size: 13px;
  }

  .sample-expression p {
    margin-bottom: 5px;
    font-size: 16px;
  }

  .sample-expression label {
    font-size: 16px;
  }

  .unzoom {
    zoom: 1.575;
    transform: scale(0.63);
  }

  .sample-expression label {
    font-size: 16px;
  }

  .file-upload {
    font-size: 16px;
  }

  .file-upload label {
    font-size: 14px;
  }

}

.custom-select-style .MuiSelect-select {
  @apply text-center xl:text-sm 2xl:text-lg;
}

.custom-select-style .MuiSelect-icon {
  color: white;
  /* Change the color here */
}
